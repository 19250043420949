import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import he from 'he'

import SEO from '../../components/seo'

class PastProjectsPage extends Component {

  render() {
    const { pastProjects } = this.props.data

    return (
      <>
        <SEO title='Past Projects - Eisen Property' bodyClass='projects' description='Working to achieve  the best for  Developers & Purchasers' />
        <section className='projects'>
          <div className='projects__inner'>
            { pastProjects && pastProjects.edges.map((el, i) => (
              <div className='projects__item' style={{backgroundImage: `url(${el.node.acf.featured_image.localFile.childImageSharp.original.src})`}}>
                <div className='projects__inner'>
                  { el.node.title &&
                    <h4 className='projects__title'><Link to={`/project/${el.node.slug}`}>{ he.decode(el.node.title) }</Link></h4>
                  }
                </div>
              </div>
            ))}
          </div>
        </section>
      </>
    )
  }
}

export default PastProjectsPage

export const pastProjectsQuery = graphql`
  query {
    pastProjects: allWordpressWpProject(filter: { categories: { elemMatch: { name: {eq: "Past"} } } }, sort: {fields: [menu_order], order: ASC}) {
      edges {
        node {
          title
          slug
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
          }
          acf {
            featured_image {
              ...original
            }
          }
        }
      }
    }
  }
`
